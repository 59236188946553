<template>
	<div class="ml-2">
		<dashboard-chip :value="appointment.quota"></dashboard-chip>	
	</div>	
</template>
<script>

import DashboardChip from '@c/dashboard/DashboardChip.vue';
export default {
	name: "GroupAppointmentInfo",
	props: {
		value: { type: String },
	},
	computed: {
		appointment() {
			return this.$store.state.appointments.data[this.value];
		},
	},
	components: { 
		DashboardChip
	},
}

</script>