export default {
	firestorePath: "appointmentParticipants",
	firestoreRefType: "collection",
	moduleName: "appointmentParticipants",
	statePropName: "data",
	namespaced: true,
	state: {
	},
	sync: {
	},
	serverChange: {
		addedHook: function(updateStore, doc, _, { dispatch }) {
			if( doc.response ){
				dispatch("surveyResponses/fetchById", doc.response );
			}
			dispatch("users/fetchById", doc.user );
			return updateStore(doc);
		},
	},
	mutations: {},
	actions: {},
	model: {},
};
