export default {
	firestorePath: "surveyResponses",
	firestoreRefType: "collection",
	moduleName: "surveyResponses",
	statePropName: "data",
	namespaced: true,
	state: {},
	sync: {
		// where: [["status", "in", ["published"]]],
		// defaultValues: {
		// 	status: "published",
		// },
	},
	serverChange: {
	},
	getters: {

    },
	mutations: {},
	actions: {
        fetchBySurvey({dispatch}, id ){
            dispatch( "openDBChannel", {clauses: {where: [[ "survey", "==", id ]]}})
        }
    },
	model: {},
};
