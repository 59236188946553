<template>
	<v-chip small class="mr-1 mb-1 " :color="color" dark>
		{{ value }}
	</v-chip>
</template>

<script>
export default {
	name: "DashboardChip",
	props: {
		value: { type: [String, Number] },
		i: { type: Number, default: 0 },
	},
	computed: {
		color() {
			return this.$store.state.colors[this.i];
		},
	},
};
</script>
