export default {
	firestorePath: "videoRooms",
	firestoreRefType: "collection",
	moduleName: "videoRooms",
	statePropName: "data",
	namespaced: true,
	sync: {
		defaultValues: {
			project: null,
			clients: [], 
			members: [], 
			identities: {}, 
			guests: [],
			waiting_room: [],
			participants: [],
			start: {},
			status: "started",
			prompt: {},
			moderator: {}, 
		}
	},
	actions: {
		createRoom({rootState, rootGetters, dispatch}, appointment ){
			let participants = rootGetters.getByKey("appointmentParticipants", "appointment", appointment.id );
			participants = participants.map( p => p.user );
			let project = rootState.projects.data[appointment.project];
			if( !project ){
				return;
			}			
			let room = {
				project: appointment.project,
				start: appointment.start,
				participants: participants, 
				clients: project.clients,
			}
			return dispatch("insert", room ).then( id => {
				dispatch("appointments/patch", {id: appointment.id, video_room: id }, {root: true} );
				dispatch("videoRoom/fetchRoom", id, {root: true });
			})
		},
	},
};
