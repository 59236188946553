<template>
	<v-app-bar app flat color="white">
		<v-toolbar-title class="font-weight-light d-none d-md-block">
			<h1 class="mt-0">
				<span style="font-size: 20px">{{ siteTitle }}</span>
				<span v-if="organisation.logo">
					<img height="30" class="ml-2" :src="organisation.logo" />
				</span>
			</h1>
		</v-toolbar-title>
		<v-spacer></v-spacer>
		<promoted-content v-if="loggedIn"></promoted-content>
		<span v-if="loggedIn">Your timezone:</span>
		<span class="primary--text ml-3">{{ timezone }}</span>
		<mw-dialog v-model="dialog" small @submit="dialog = false" submitBtn>
			<template v-slot:button>
				<v-btn color="primary" icon @click="dialog = true">
					<v-icon>mdi-cog</v-icon>
				</v-btn>
			</template>
			<template v-slot:dialog-body>
				<timezone-selector
					:value="timezone"
					@input="changeTz"
				></timezone-selector>
			</template>
		</mw-dialog>
	</v-app-bar>
</template>

<style lang="less" scoped>
img {
	cursor: pointer;
}
</style>
<script>
import MwDialog from "@c/ui/MwDialog";
import TimezoneSelector from "@c/ui/TimezoneSelector";
import PromotedContent from "@c/promoted-content/Button";

export default {
	name: "TopNav",
	data: () => {
		return {
			dialog: false,
		};
	},
	inject: ["isMobile"],
	computed: {
		organisation() {
			return this.$store.state.organisation.data;
		},
		height() {
			return this.isMobile ? 50 : 80;
		},
		user() {
			return this.$store.state.auth.data;
		},
		loggedIn() {
			return this.user.id ? true : false;
		},
		timezone() {
			return this.user.timezone || "Europe/London";
		},
		siteTitle() {
			return this.$store.state.site_name;
		},
	},
	components: {
		TimezoneSelector,
		MwDialog,
		PromotedContent
	},
	methods: {
		goHome() {
			if (this.$route.path !== "/") {
				this.$router.push("/");
			}
		},
		changeTz(v) {
			this.$store.dispatch("auth/patch", { timezone: v });
		},
	},
};
</script>
