<template>
	<v-menu offset-y offset-x>
		<template v-slot:activator="{ on }">
			<v-btn icon absolute right v-on="on">
				<v-icon>mdi-menu</v-icon>
			</v-btn>
		</template>
		<v-list dense>
			<v-list-item
				v-for="(item, index) in items"
				:key="index"
				v-bind="item.bind"
			>
				<v-list-item-content>
					<v-list-item-title
						><span style="text-transform: uppercase">{{
							item.title
						}}</span></v-list-item-title
					>
					<v-list-item-subtitle>{{ item.subtitle }}</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
		</v-list>
	</v-menu>
</template>
<script>
export default {
	name: "AppointmentTileMenu",
	props: {
		value: { type: String },
	},
	data: () => {
		return {};
	},
	computed: {
		items() {
			let items = [
				{
					title: "Details & Actions",
					subtitle: "View this participant's profile and consent, or let us know the interview is complete",
					bind: {
						"router-link": true,
						to: {path: `/projects/${this.project.id}/sessions?a=${this.value}` },
					},
				}
			]
			if( !this.appointment.participation_link ){
				items.push( {
					title: "Add Meeting Link",
					subtitle:
						"Participants will access your meeting link via this portal",
					bind: {
						"router-link": true,
						to: {name: 'Project', params: { viewType: 'sessions', id: this.project.id }, query: { t: this.value }},
					},
				})
			}
			items.push( 
				{
					title: "Project",
					subtitle:
						"View details about this and other appointments for this project",
					bind: {
						"router-link": true,
						to: `/projects/${this.project.id}/participants`,
					},
				},
			)
			return items;
		},
		appointment() {
			return this.$store.state.appointments.data[this.value];
		},
		project() {
			return this.$store.state.projects.data[this.appointment.project];
		},
	},
};
</script>
