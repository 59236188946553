<template>
	<mw-dialog
		v-model="dialog"
		cancel-btn
		submit-btn
		@cancel="$emit('close')"
		@submit="submit"
	>
		<template v-slot:dialog-body>
			<p v-if="isPhone">
				The participant can be reached on {{ user.phone }}
			</p>
			<span v-if="isVideo">
				<p v-if="isInternal">
You're about to enter a remote video appointment. Please note
						that sessions are recorded automatically. If you have any
						concerns or questions, please get in touch with us using the
						live chat (bottom left).
				</p>
				<p v-else>
					<span v-if="haveLink"
						>You are starting a video appointment in a third party
						application. Press submit to continue</span
					>
					<span v-else
						>There is no link associated with this appointment. Please
						contact meisterworks via the live chat.</span
					>
				</p>
			</span>
		</template>
	</mw-dialog>
</template>

<script>
import MwDialog from "@c/ui/MwDialog";
export default {
	name: "ClickAppointment",
	props: {
		value: { type: String },
	},
	components: {
		MwDialog,
	},
	data: () => {
		return {
			dialog: false,
		};
	},
	computed: {
		appointment() {
			return this.$store.state.appointments.data[this.value];
		},
		appointmentParticipants() {
			return this.$store.getters.getByKey(
				"appointmentParticipants",
				"appointment",
				this.value
			);
		},
		isInternal() {
			return this.appointment
				? this.appointment.video_medium == "internal"
				: false;
		},
		isVideo() {
			return this.appointment
				? this.appointment.methodology == "video"
				: false;
		},
		haveRoom() {
			if (!this.appointment) {
				return false;
			}
			return this.appointment.video_room ? true : false;
		},
		haveLink() {
			if (!this.appointment) {
				return false;
			}
			return this.appointment.participation_link ? true : false;
		},
		isPhone() {
			return this.appointment
				? this.appointment.methodology == "phone"
				: false;
		},
		user() {
			if (!this.appointmentParticipants) {
				return {};
			}
			let a = this.appointmentParticipants[0];
			return this.$store.state.users.data[a.user];
		},
	},
	methods: {
		submit() {
			if (this.isVideo) {
				if (this.isInternal) {
					this.$router.push("/video/" + this.value);
				} else {
					if (this.haveLink) {
						let link = this.appointment.participation_link;
						if (link.substr(0, 4) !== "http") {
							link = "http://" + link;
						}
						window.open(link, "_blank");
					}
				}
			}
			this.dialog = false;
		},
	},
	watch: {
		dialog(v) {
			if (!v) {
				this.$emit("close");
			}
		},
		value: {
			immediate: true,
			handler(v) {
				if (v) {
					this.dialog = true;
				}
			},
		},
	},
};
</script>
