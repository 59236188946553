<template>
	<v-card
		width="100%"
		outlined
		flat
		tile
		class="pa-2 mb-2 font-weight-light"
		v-if="appointment"
	>
		<v-row>
			<v-col cols="12" lg="1" sm="1">
				<v-icon class="mt-1" x-large> {{ logo }}</v-icon>
			</v-col>
			<v-col cols="12" lg="2" sm="3">
				<span style="font-size: 1.2em">

					{{
						mwtimeutils
							.parseDateToMoment(appointment.start)
							.format("h:mma")
					}}
				</span>
				<br/>
				<v-btn
					x-small
					:disabled="!isSoon"
					v-if="canClick"
					color="primary"
					@click="$emit('click', value)"
				>
					Enter Session
				</v-btn>
			</v-col>
			<v-col cols="12" lg="3" sm="4">
				<span style="font-size: 1.2em">{{ project.client_name }}</span>
			</v-col>
			<v-col cols="12" lg="3" sm="5">
				<template v-if="individual">
				<participant-info
					
					:value="appointmentParticipants[0].id"
				></participant-info>
				</template>
				<span v-if="group" style="font-size: 22px">
					Group of {{ appointmentParticipants.length }}
				</span>
			</v-col>
			<v-col cols="12" lg="3" sm="3">
				<appointment-tile-menu :value="value"></appointment-tile-menu>
				<v-component :is="component" :value="value"></v-component>
			</v-col>
		</v-row>
	</v-card>
</template>

<style lang="less">
.large-text {
	font-size: 1.4em;
}
</style>
<script>
import ParticipantInfo from "@c/dashboard/ParticipantInfo";
import GroupAppointmentInfo from "@c/dashboard/GroupAppointmentInfo";
import IndividualAppointmentInfo from "@c/dashboard/IndividualAppointmentInfo";
import AppointmentTileMenu from "@c/dashboard/AppointmentTileMenu";
export default {
	name: "AppointmentTile",
	props: {
		value: { type: String },
	},
	computed: {
		appointment() {
			return this.$store.state.appointments.data[this.value];
		},
		project() {
			return this.$store.state.projects.data[this.appointment.project];
		},
		appointmentParticipants() {
			return this.$store.getters.getByKey(
				"appointmentParticipants",
				"appointment",
				this.value
			);
		},
		isSoon(){
			let now = new Date();
			let start = this.mwtimeutils.parseDateToMoment( this.appointment.start );
			start = start.subtract(10, "minutes");
			return start < now;
		},
		canClick() {
			return this.appointment.methodology !== "f2f";
		},
		individual() {
			return this.appointmentParticipants.length == 1;
		},
		group() {
			return this.appointmentParticipants.length > 1;
		},
		appointmentParticipantIds() {
			return this.appointmentParticipants.map((a) => a.id);
		},
		component() {
			if (this.individual) {
				return "IndividualAppointmentInfo";
			}
			if (this.group) {
				return "GroupAppointmentInfo";
			}
			return false;
		},
		logo() {
			if (this.appointment.methodology == "phone") {
				return "mdi-phone";
			}
			if (this.appointment.methodology == "video") {
				if (this.appointment.video_medium == "google meet") {
					return "mdi-google";
				}
				if (this.appointment.video_medium == "zoom") {
					return "mdi-alpha-z-circle";
				}
				if (this.appointment.video_medium == "ms teams") {
					return "mdi-microsoft-teams";
				}
				if (this.appointment.video_medium == "skype") {
					return "mdi-skype";
				}
				return "mdi-message-video";
			}
			return "";
		},
	},
	components: {
		IndividualAppointmentInfo,
		GroupAppointmentInfo,
		ParticipantInfo,
		AppointmentTileMenu
	},
};
</script>
