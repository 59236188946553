export default {
	firestorePath: "users",
	firestoreRefType: "collection",
	moduleName: "users",
	statePropName: "data",
	namespaced: true,
	state: {},
	sync: {
	},

	mutations: {},
	actions: {
	},
};
