<template>
	<v-row style="margin-top: 100px; margin-bottom: 100px">
		<v-col cols="12" sm="6">
			<v-img
				max-height="275"
				contain
				aspect-ratio="1"
				class="mx-auto"
				:src="image"
				v-if="left"
			></v-img>
			<div class="image-row-caption" v-else>
				<slot name="text"></slot>
			</div>
		</v-col>
		<v-col cols="12" sm="6" :order="left ? 'last' : 'first'" order-sm="first">
			<div class="image-row-caption" v-if="left" >
				<slot name="text"></slot>
			</div>
			<v-img
				max-height="275"
				contain
				aspect-ratio="1"
				class="mx-auto"
				:src="image"
				v-else
			></v-img>
		</v-col>
	</v-row>
</template>

<style lang="less">
.image-row-caption {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

}
</style>
<script>
export default {
	name: "ImageRow",
	props: {
		image: { type: String },
		left: { type: Boolean },
	},
};
</script>
