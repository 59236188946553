<template>
	<v-footer color="white" :absolute="!isMobile" v-if="isHome">
		<v-container class="pr-8 pt-8 pl-8">
			<v-divider class="mb-8"></v-divider>
			<v-row>
				<v-col cols="12" sm="5">
					<router-link to="/website-policies-and-notices"
						>Website Policies and Notices</router-link
					><br />
					<router-link to="/research-policies-and-notices"
						>Research Policies</router-link
					><br />
				</v-col>
				<v-col cols="12" sm="5">
					<v-img
						max-width="200"
						class="mt-2"
						src="./../../assets/images/logo2.png"
					/>
					<!-- Meisterworks Ltd<br /> -->
					<small v-html="address"></small><br />
				</v-col>
				<v-col cols="12" sm="2">
					<v-img
						max-height="180"
						max-width="100"
						class="mt-2"
						src="https://firebasestorage.googleapis.com/v0/b/mwx2-753d3.appspot.com/o/credentials1.63360cd0.png?alt=media&token=9ed985cd-cc51-44e4-ae57-477349d1919a"
					/>
				</v-col>
			</v-row>
			<v-row>
				<v-col class="text-center secondary--text">
					<small
						><i
							>We hope that the content on this page is helpful to you.
							If you have any ideas for improving this page or if there
							are topics you'd like to see included here, please let us
							know by using the live chat or via the
							<router-link to="/contact">contact us</router-link>
							page.</i
						></small
					>
				</v-col>
			</v-row>
		</v-container>
	</v-footer>
</template>

<script>
export default {
	name: "SiteFooter",
	inject: ["isMobile"],
	computed: {
        isHome(){
            return this.$route.name == "Home"
        },
		address() {
			return this.$store.state.address;
		},
		email() {
			return this.$store.state.email;
		},
		loggedIn() {
			return this.$store.state.auth.logged_in;
		},
	},
};
</script>
