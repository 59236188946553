export default {
	firestorePath: "projects",
	firestoreRefType: "collection",
	moduleName: "projects",
	statePropName: "data",
	namespaced: true,
	state: {
		icon: "mdi-archive-edit",
		name: {
			single: "Project", 
			plural: "Projects"
		},
		path: "/projects"
	},
	sync: {
		where: [
			["status", "in", ["published"]]
		], 
		defaultValues: {
			status: "published"
		},
	},
	getters: {
		name(state){
			return (id) => {
				let project = state.data[id];
				return project ? project.client_name : "";
			}
		}
	},
	mutations: {},
	actions: {
		fetchAll({dispatch, rootState} ){
			let user = rootState.auth.data.id;
			if( !user ){
				return;
			}
			return dispatch("openDBChannel",  {
				clauses: {
					where: [
						["clients", "array-contains", user ],
						["display_to_client", "==", true ] 
					]
				}
			})
		},
		fetchCurrentProjects({dispatch, rootState} ){
			let user = rootState.auth.data.id;
			if( !user ){
				return;
			}
			return dispatch("openDBChannel",  {
				clauses: {
					where: [
						["clients", "array-contains", user ],
						["project_status", "==", "active" ],
						["display_to_client", "==", true ] 
					]
				}
			})
		}
    },
};
