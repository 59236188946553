<template>
	<v-sheet color="transparent" class="pa-2">
		<h3 class="font-weight-light mb-5">
			Upcoming Appointments
		</h3>
		<p>
			You can check for appointments scheduled for any of your projects
			below. Further details on individuals appointments can be accessed
			using the links on each of the appointment cards shown.
		</p>
		<div v-for="(day, str) in days" :key="str" class="mb-7">
			<h4 class="font-weight-light mb-5">
				{{ str }}
			</h4>
			<appointment-tile
				class="tile"
				v-for="appointment in day"
				:key="appointment.id"
				:value="appointment.id"
				@click="focus = appointment.id"
			></appointment-tile>
		</div>
		<click-appointment
			:value="focus"
			@close="focus = null"
		></click-appointment>
		<p class="text-right" v-if="!error">
			<v-btn text @click="loadMore(true)">Load More Appointments</v-btn>
		</p>
	</v-sheet>
</template>
<style lang="less">
.tile {
	margin-right: 10px;
	display: inline-block;
}
</style>
<script>
import AppointmentTile from "@c/dashboard/AppointmentTile";
import ClickAppointment from "@c/project/ClickAppointment";
export default {
	name: "CurrentAppointments",
	data: () => {
		return {
			focus: null,
			error: null,
		};
	},
	components: {
		AppointmentTile,
		ClickAppointment,
	},
	computed: {
		appointments() {
			return this.$store.getters["appointments/futureAppointmentsSorted"];
		},
		projects() {
			return this.$store.state.projects.data;
		},
		haveProjects() {
			return Object.keys(this.projects).length > 0 ? true : false;
		},

		days() {
			const self = this;
			let days = {};
			self.appointments.forEach((a) => {
				let m = self.mwtimeutils.parseDateToMoment(a.start);
				let day = m.format("dddd, MMMM Do YYYY");
				if (!days[day]) {
					days[day] = [];
				}
				days[day].push(a);
			});
			return days;
		},
	},
	watch: {
		haveProjects: {
			immediate: true,
			handler(v) {
				if (v) {
					this.loadMore(false);
				}
			},
		},
	},
	methods: {
		loadMore(last) {
			const self = this;
			if (last) {
				last = self.appointments[self.appointments.length - 1];
				last = last.start.toDate();
			}
			self.$store.dispatch("appointments/myNext10", last).then((fetch) => {
				if (fetch.done) {
					self.error = true;
				}
			});
		},
	},
};
</script>
