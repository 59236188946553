<template>
	<div class="bottom-contents-wrapper">
		<template v-if="participant">
			<dashboard-chip class="bottom-content" :key="i" v-for="(v, i) in chips" :i="i" :value="v"></dashboard-chip><br/>
		</template>
	</div>
</template>
<style lang="less">
	.bottom-contents-wrapper {
		height: 100%;
		display: inline-flex;
		flex-wrap: wrap;
		.bottom-content {
			align-self: flex-end;
		}
	}
	
</style>
<script>
import DashboardChip from '@c/dashboard/DashboardChip.vue';
export default {
	name: "IndividualAppointmentInfo",
	props: {
		value: { type: String },
	},
	computed: {
		appointment() {
			return this.$store.state.appointments.data[this.value];
		},
		appointmentParticipants() {
			return this.$store.getters.getByKey(
				"appointmentParticipants",
				"appointment",
				this.value
			);
		},
		participant() {
			return this.appointmentParticipants[0];
		},
		chips() {
			let chips = [];
			if( this.participant.appointment_status ){
				chips.push( this.mwutils.prettyPrint( this.participant.appointment_status, "TITLE" ) );
			}
			if( this.participant.quota ){
				chips.push(this.participant.quota);
			}
			if( this.participant.consented ){
				chips.push( "Consented" );
			}
			return chips;
		},
	},
	components: { 
		DashboardChip
	},
};
</script>

DashboardChip