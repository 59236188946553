<template>
	<div class="text-center mr-4 tile inline-tile">
		<v-avatar tile :color="useColor" class="mx-auto mb-0">
			<span class="large-text font-weight-bold" :class="'dark' ? '' : 'white--text'"> {{ value }} </span>
		</v-avatar>
        <span class="overline small mb-0"><br/>{{text}}</span>
	</div>
</template>

<style lang="less">
    .overline.small {
        font-size: 0.6em !important;
    }
</style>

<script>
export default {
	name: "DashboardAvatar",
	props: {
		dark: {type: Boolean},
		color: { type: String },
		text: { type: String },
		value: { type: [String, Number] },
		i: { type: Number, default: 0 },
	},
	computed: {
		useColor() {
			if( this.color ){
				return this.color;
			}
			return this.$store.state.colors[this.i];
		},
	},
};
</script>
