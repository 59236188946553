export default {
	firestorePath: "surveys",
	firestoreRefType: "collection",
	moduleName: "surveys",
	statePropName: "data",
	namespaced: true,
	state: {
	},
	sync: {
		where: [["status", "in", ["published"]]],
	},	
	serverChange: {
		addedHook: function(updateStore, doc, _, { dispatch }) {
			var questions = doc.questions;
			dispatch("questions/fetchByIds", questions);
			return updateStore(doc);
		},
	},
	getters: {
		questionsForResults(state, _, rootState  ){
			return (id) => {
				let survey = state.data[id];
				if( !survey || !id  ){
					return null;
				}
				let questions = survey.questions.map( q => rootState.questions.data[q] );
				questions = questions.filter( q => q );
				questions = questions.filter(q => !['html', 'header'].includes(q.type) );
				questions = questions.filter( q => !q.hide_from_results );
				return questions.map( q => q.id );
			}
		}
	},
	mutations: {},
	actions: {},
	model: {},
};
