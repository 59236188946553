<template>
	<v-chip v-if="dateString" color="darkblue" text outlined>
		{{ dateString }}
	</v-chip>
</template>

<script>
export default {
	name: "ProjectDates",
	props: {
		value: { type: String },
	},
	computed: {
		project() {
			return this.$store.state.projects.data[this.value];
		},
		dateString() {
			let str = "";
			if (this.project.start) {
				if (!this.project.end) {
					str += "From ";
				}
				str += this.mwtimeutils.formatAsDateString(this.project.start);
			}
			if (this.project.end) {
				if (!str.length) {
					str = "Until ";
				} else {
					str += " - ";
				}
				str += this.mwtimeutils.formatAsDateString(this.project.end);
			}
			return str;
		},
	},
};
</script>
