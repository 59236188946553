<template>
	<v-sheet>
		<template v-if="loggedIn">
			<render-content id="clientaboutloggedin"></render-content>
		</template>
		<template v-else>
			<render-content id="clientaboutnotloggedin"></render-content>
			<v-row class="mt-2">
				<v-col cols="12" sm="6" class="text-justify">
					<render-content id="clientaboutnotloggedinleft"></render-content>
				</v-col>
				<v-col cols="12" sm="6">
					<v-card flat color="lightest" class="pa-5">
						<render-content 
							id="clientaboutnotloggedinright"
						></render-content>
					</v-card>
				</v-col>
			</v-row>
		</template>
			<image-row :image="require(`@/assets/images/always_up_to_datee.png`)" left>
				<template v-slot:text>
					<render-content id="alwaysuptpdate" ></render-content>
				</template>
			</image-row>
			<image-row :image="require(`@/assets/images/everything_in_one_place.png`)" >
				<template v-slot:text>
					<render-content id="everythinginoneplace"></render-content>
				</template>
			</image-row>
			<image-row :image="require(`@/assets/images/privacy_by_default.png`)"  left>
				<template v-slot:text>
					<render-content id="privacybydefault"></render-content>
				</template>
			</image-row>
			<image-row :image="require(`@/assets/images/work_the_way_youre_used_to.png`)"  >
				<template v-slot:text>
					<render-content id="workthewayyoureusedto"></render-content>
				</template>
			</image-row>
			<image-row :image="require(`@/assets/images/secure_remote_sessions.png`)"  left>
				<template v-slot:text>
					<render-content id="secureremotesessions"></render-content>
				</template>
			</image-row>
			<image-row :image="require(`@/assets/images/easy_to_reach_participants.png`)"  >
				<template v-slot:text>
					<render-content id="easilyreachparticipants"></render-content>
				</template>
			</image-row>
			<image-row :image="require(`@/assets/images/one_click_outcome_reporting.png`)"  left>
				<template v-slot:text>
					<render-content id="oneclickoutcomereporting"></render-content>
				</template>
			</image-row>
			<image-row :image="require(`@/assets/images/access_for_the_whole_team.png`)" >
				<template v-slot:text>
					<render-content id="accessforthewholeteam" ></render-content>
				</template>
			</image-row>
			<v-row class="mt-2" v-if="!loggedIn">
				<v-col cols="12" sm="6" class="text-justify">
				</v-col>
				<v-col cols="12" sm="6">
					<v-card flat color="lightest" class="pa-5">
						<render-content 
							id="clientaboutnotloggedinright"
						></render-content>
					</v-card>
				</v-col>
			</v-row>
	</v-sheet>
</template>

<script>
import RenderContent from "@c/ui/RenderContent";
import ImageRow from "@c/ui/ImageRow";
export default {
	name: "About",
	components: {
		RenderContent,
		ImageRow,
	},
	computed: {
		loggedIn() {
			return this.$store.state.auth.logged_in;
		},
	},
	data: () => {
		return {
			images: [],
		};
	},
};
</script>
