<template>
	<div v-if="loggedIn">
		<render-content id="home"></render-content>
		<current-appointments v-if="loggedIn"></current-appointments>
		<current-projects v-if="loggedIn"></current-projects>
	</div>
	<about v-else> </about>
</template>

<script>
import RenderContent from "@c/ui/RenderContent";
import CurrentAppointments from "@c/dashboard/CurrentAppointments";
import CurrentProjects from "@c/dashboard/CurrentProjects";
import About from "./About.vue";
export default {
	name: "Home",
	components: {
		RenderContent,
		CurrentAppointments,
		About,
		CurrentProjects,
	},
	computed: {
		loggedIn() {
			return this.$store.state.auth.data.id ? true : false;
		},
	},
};
</script>
