import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import firebase from "firebase";
Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
	},
	{
		path: "/info/about",
		name: "About",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/About.vue"),
	},
	{
		path: "/info/recruitment",
		name: "ParticipantRecruitment",
		component: () =>
			import(/* webpackChunkName: "ParticipantRecruitment" */ "../views/ParticipantRecruitment.vue"),
	},
	{
		path: "/tech-test",
		name: "TechTest",
		component: () =>
			import(/* webpackChunkName: "TechTest" */ "../views/TechTest.vue"),
	},
	{
		path: "/profile",
		name: "Profile",
		component: () =>
			import(/* webpackChunkName: "profile" */ "../views/Profile.vue"),
	},
	{
		path: "/info/frequently-asked-questions",
		name: "FAQs",
		component: () =>
			import(/* webpackChunkName: "FAQs" */ "../views/FAQs.vue"),
	},

	{
		path: "/projects/:id/:viewType",
		name: "Project",
		props: true,
		meta: {
			requires_auth: true
		},
		component: () =>
			import(/* webpackChunkName: "project" */ "../views/Project.vue"),
	},
	{
		path: "/projects",
		name: "Projects",
		meta: {
			requires_auth: true
		},
		component: () =>
			import(/* webpackChunkName: "Projects" */ "../views/Projects.vue"),
	},
	{
		path: "/recordings/:id",
		name: "Recordings",
		props: true,
		meta: {
			requires_auth: true
		},
		component: () =>
			import(/* webpackChunkName: "Recordings" */ "../views/Recordings.vue"),
	},
	{
		path: "/incentives/:id/import",
		name: "IncentivesImport",
		props: true,
		meta: {
			requires_auth: true
		},
		component: () =>
			import(/* webpackChunkName: "Import Incentives" */ "@c/incentives/Import.vue"),
	},
	{
		path: "/incentives/:id/add",
		name: "IncentivesAdd",
		props: true,
		meta: {
			requires_auth: true
		},
		component: () =>
			import(/* webpackChunkName: "Add Incentives" */ "@c/incentives/Add.vue"),
	},
	{
		path: "/incentives/:id/view",
		name: "IncentivesView",
		props: true,
		meta: {
			requires_auth: true
		},
		component: () =>
			import(/* webpackChunkName: "view Incentives" */ "@c/incentives/View.vue"),
	},
	{
		path: "/documents/:id",
		name: "Documents",
		props: true,
		meta: {
			requires_auth: true
		},
		component: () =>
			import(/* webpackChunkName: "Project" */ "../views/Documents.vue"),
	},
	{
		path: "/video/:id",
		name: "Video",
		props: true,
		component: () =>
			import(/* webpackChunkName: "Project" */ "../views/Video.vue"),
	},
	{
		path: "/contact",
		name: "Contact",
		meta: {},
		component: () =>
			import( "../views/Contact.vue"),
	},
	{
		path: "/website-policies-and-notices",
		name: "WebsitePoliciesAndNotices",
		meta: {},
		component: () =>
			import( "../views/WebsitePoliciesAndNotices.vue"),
	},
	{
		path: "/research-policies-and-notices",
		name: "ResearchPoliciesAndNotices",
		meta: {},
		component: () =>
			import( "../views/ResearchPoliciesAndNotices.vue"),
	},
	{
		path: "/thank-you",
		name: "ThankYou",
		meta: {},
		component: () =>
			import( "../views/ThankYou.vue"),
	},
	{
		path: "/error",
		name: "Error",
		meta: {},
		component: () =>
			import(/* webpackChunkName: "error" */ "../views/Error.vue"),
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach(async (to, from, next) => {
	const requiresAuth = to.matched.some((record) => record.meta.requires_auth);
	if (requiresAuth && !(await firebase.getCurrentUser())) {
		next("/");
	} else {
		next();
	}
});

export default router;
