<template>
    <span style="font-size: 1.2em">
        <span v-if="contactDetails">{{contactDetails.name}}</span>
        <span v-else>&nbsp;</span>    
    </span>
</template>


<script>
export default {
    name: "ParticipantInfo", 
    props: {
        value: {type: String}
    }, 
    computed: {
        contactDetails(){
            return this.$store.getters.getParticipantContacts(this.value);
        }
    }
}
</script>