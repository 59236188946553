import utilities from "../../assets/utilities";

export default {
	firestorePath: "content",
	firestoreRefType: "collection",
	moduleName: "content",
	statePropName: "data",
	namespaced: true,
	state: {},
	getters: {
		unpromoted(state){
			let unpromoted = Object.values(state.data);
			unpromoted = unpromoted.filter( a => a.live_content && a.live_content.includes("client") );
			unpromoted = unpromoted.filter( a => {
				if( !a.promote_content ) {
					return true;
				}
				let d = a.promote_content.toDate();
				return d < new Date();
			});
			return unpromoted;
		},
		promoted(state){
			let promoted = Object.values(state.data);
			promoted = promoted.filter( a => a.live_content && a.live_content.includes("client") );
			promoted = promoted.filter( a => {
				if( !a.promote_content ) {
					return false;
				}
				let d = a.promote_content.toDate();
				return d > new Date();
			});
			promoted = utilities.sortByDate(promoted, "updated_at");
			promoted = promoted.reverse();
			return promoted;
		},
		top5(state, getters){
			let promoted = getters.promoted;
			let unpromoted = getters.unpromoted;
			unpromoted = unpromoted.sort( () => {  
				return 0.5 - Math.random();
			})
			return promoted.concat( unpromoted.splice( 0, 5-promoted.length ));
		},
	},
	mutations: {},
	actions: {
		fetchUnpromoted({dispatch }) {
			return dispatch("fetchAndAdd", {
				clauses: {
					where: [
						["live_content", "array-contains", "client"],
						["status", "==", "published"],
					]
				}
			})
		},
		fetchPromoted({dispatch}){
			return dispatch("fetchAndAdd", {
				clauses: {
					where: [
						["live_content", "array-contains", "client"],
						["promote_content", ">", new Date()],
						["status", "==", "published"],
					],
					orderBy: ["promote_content"],
					limit: 5
				}
			}).then( (res) => {
				if( res ){
					if( res.empty || res.docs.length < 5 ){
						dispatch("fetchUnpromoted");
					}
				}
			})
		}
	},
};
