import timezones from "./timezones";

export default {
	timezones() {
		return timezones;
	},
	getByKey(state) {
		return (storeModule, key, value, returnObject) => {
			let items = state[storeModule].data;
			items = Object.values(items);
			items = items.filter((i) => i[key] == value);
			if (returnObject) {
				items = Object.fromEntries(items.map((v) => [v.id, v]));
			}
			return items;
		};
	},
	getByKeyDoesNotContain(state) {
		return (storeModule, key, value, returnObject) => {
			let items = state[storeModule].data;
			items = Object.values(items);
			items = items.filter((i) => !i[key] || !i[key].includes(value));
			if (returnObject) {
				items = Object.fromEntries(items.map((v) => [v.id, v]));
			}
			return items;
		};
	},
	getByKeyContains(state) {
		return (storeModule, key, value, returnObject) => {
			let items = state[storeModule].data;
			items = Object.values(items);
			items = items.filter((i) => i[key] && i[key].includes(value));
			if (returnObject) {
				items = Object.fromEntries(items.map((v) => [v.id, v]));
			}
			return items;
		};
	},
	getByKeyIn(state) {
		return (storeModule, key, value, returnObject) => {
			let items = state[storeModule].data;
			items = Object.values(items);
			items = items.filter((i) => value.includes(i[key]));
			if (returnObject) {
				items = Object.fromEntries(items.map((v) => [v.id, v]));
			}
			return items;
		};
	},
	getParticipantContacts(state) {
		return (id) => {
			let appointmentParticipant = state.appointmentParticipants.data[id];
			if (!appointmentParticipant) {
				return null;
			}
			let appointment =
				state.appointments.data[appointmentParticipant.appointment];
			let project = state.projects.data[appointment.project];
			let user = state.users.data[appointmentParticipant.user];
			if (!user) {
				return null;
			}
			let name = "";
			name = user.first_name;
			if( !name ){
				if( user.name ){
					name = user.name.split(" ")[0]
				}
			}
			let contacts = { name };
			if (!project.hide_contact_details) {
				contacts = {
					email: user.email,
					phone: user.phone,
					name: user.name,
				};
			}

			return contacts;
		};
	},
};
