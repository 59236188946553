import Vue from "vue";
import Vuex from "vuex";
import { createStore } from "vuex-extensions";
import VuexEasyFirestore from "vuex-easy-firestore";
Vue.use(Vuex);

import state from "./state";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

import firebase from "firebase";
import auth from "./modules/auth.js";
import emails from "./modules/emails.js";
import messages from "./modules/messages.js";
import users from "./modules/users.js";
import content from "./modules/content.js";
import consents from "./modules/consents.js";
import incentiveSubmissions from "./modules/incentiveSubmissions.js";

import appointmentParticipants from "./modules/appointmentParticipants.js";
import appointments from "./modules/appointments.js";
import singleAppointments from "./modules/singleAppointments.js";
import projects from "./modules/projects.js";
import surveyResponses from "./modules/surveyResponses.js";
import surveys from "./modules/surveys.js";
import questions from "./modules/questions.js";

import tableHeaders from "./modules/tableHeaders.js";

import videoRoom from "./modules/videoRoom";
import organisation from "./modules/organisation";
import videoRooms from "./modules/videoRooms";

const vxf = VuexEasyFirestore(
	[
		auth,
		appointments,
		appointmentParticipants,
		content,
		consents,
		emails,
		incentiveSubmissions,
		messages,
		organisation,
		users,
		projects,
		surveyResponses,
		surveys,
		questions,
		videoRoom,
		videoRooms,
	],
	{
		logging: true,
		FirebaseDependency: firebase,
	}
);

const mwstoreutils = {
	actions: {
		fetchByKey: ({ dispatch }, { key, value }) => {
			dispatch(`openDBChannel`, {
				clauses: {
					where: [
						[key, "==", value],
						["status", "==", "published"],
					],
				},
			});
		},
		fetchByIds: ({ dispatch }, ids) => {
			if (!ids) {
				return;
			}
			ids.forEach((id) => {
				dispatch("fetchById", id);
			});
		},
		fetchByIdOr: ({ dispatch, rootState }, { roles, contacts }) => {
			if (!roles) {
				roles = rootState.roles;
			}
			if (!contacts) {
				contacts = [rootState.auth.data.id];
				let email = rootState.auth.data.email;
				if (email) {
					contacts.push(email);
				}
				let phone = rootState.auth.data.phone;
				if (phone && phone.length > 3) {
					contacts.push(phone);
				}
			}
			dispatch("fetchAndAdd", {
				clauses: {
					where: [
						[roles, "array-contains-any", contacts],
						["status", "==", "published"],
					],
				},
			});
		},
	},
};
const storeData = {
	actions,
	getters,
	mutations,
	state,
	modules: { tableHeaders, singleAppointments },
	plugins: [vxf],
	mixins: mwstoreutils,
};

const store = createStore(Vuex.Store, storeData);

export default store;
