export default {
	firestorePath: "incentiveSubmissions",
	firestoreRefType: "collection",
	moduleName: "incentiveSubmissions",
	statePropName: "data",
	namespaced: true,
	state: {},
	serverChange: {},
	getters: {},
	mutations: {},
	actions: {
		fetchByProject({ dispatch }, id) {
			dispatch("fetchAndAdd", {
				clauses: { where: [["project", "==", id]], limit: -1 },
			});
		},
	},
	model: {},
};
