<template>
	<div class="pr-4" v-if="top5.length">
		<v-menu offset-y right max-width="500px" z-index="100" v-model="menu">
			<template v-slot:activator="{ on }">
				<v-badge
					:value="badge"
					color="pink"
					icon="mdi-star"
					overlap
					offset-x="20"
					offset-y="20"
				>
					<v-btn icon color="primary" large v-on="on">
						<v-icon>mdi-information</v-icon>
					</v-btn>
				</v-badge>
			</template>
			<promoted-content-list></promoted-content-list>
		</v-menu>
	</div>
</template>

<script>
import PromotedContentList from "@c/promoted-content/List";

export default {
	name: "PromoteContentButton",
	data: () => {
		return {
			badge: false,
			menu: null,
		};
	},
	computed: {
		promoted() {
			return this.$store.getters["content/promoted"];
		},
		top5() {
			return this.$store.getters["content/top5"];
		},
	},
	watch: {
		menu(nv, ov) {
			if ((ov == true && nv == false)) {
				this.badge = false;
			}
		},
		promoted: {
			// immediate: true,
			handler() {
				if (this.promoted.length) {
					this.badge = true;
				}
			},
		},
	},
	components: {
		PromotedContentList,
	},
	created() {
		this.$store.dispatch("content/fetchPromoted");
	},
};
</script>
