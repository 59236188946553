export default {
	firestorePath: "organisations/{id}",
	firestoreRefType: "doc",
	moduleName: "organisation",
	statePropName: "data",
	namespaced: true,
	state: {
		data: {
		}
	},
	getters: {
	},
	actions: {
		
	},
};
