import firebase from "firebase";
import Vue from "vue";
export default {
	firestorePath: "users/{userId}",
	firestoreRefType: "doc",
	moduleName: "auth",
	statePropName: "data",
	namespaced: true,
	state: {
		logged_in: false,
		logged_out: false,
		data: {
			status: "published",
			role: "user",
			registration_method: "user",
		},
	},
	serverChange: {
		modifiedHook(updateStore, doc, _, { dispatch }) {
			var organisation = doc.organisation;
			if (organisation && organisation.length ) {
				dispatch(
					"organisation/openDBChannel",
					{ id: organisation },
					{ root: true }
				);
			}
			return updateStore(doc);
		},
	},
	getters: {
		id(state) {
			return state.data.id;
		},
		isClient(state) {
			return (
				state.data.role == "superadmin" ||
				state.data.role == "admin" ||
				state.data.role == "client"
			);
		},
		settings(state) {
			return state.data.settings || { display_appointment_columns: {} };
		},
		tableHeaders(_, getters) {
			return (id, colType) => {
				let settings = getters.settings.display_appointment_columns;
				let cols = settings[id];
				if (!cols) {
					return [];
				}
				return cols[colType] || [];
			};
		},
	},
	mutations: {
		SET_COLUMNS(state, { id, cols }) {
			Vue.set(state.data.settings.display_appointment_columns, id, cols);
		},
		SET_LOGGED_IN(state) {
			Vue.set(state, "logged_in", new Date());
		},
		SET_LOGGED_OUT(state) {
			Vue.set(state, "logged_in", false);
			Vue.set(state, "logged_out", new Date());
		},
	},
	actions: {
		start({ dispatch, commit, getters }) {
			return dispatch("openDBChannel").then(() => {
				if (getters.isClient) {
					dispatch("setLastLogin");
					commit("SET_LOGGED_IN");
					return true;
				} else {
					dispatch("stop");
					throw new Error("not-client");
				}
			});
		},
		stop({ commit, dispatch }) {
			firebase
				.auth()
				.signOut()
				.then(() => {
					commit("SET_LOGGED_OUT");
					dispatch("clearAll", false, { root: true });
					commit("RESET_VUEX_EASY_FIRESTORE_STATE");
					return true;
				});
		},
		setLastLogin({ dispatch }) {
			dispatch("patch", { last_login: new Date() });
		},
		addUserDetails({ dispatch }) {
			var user = {
				name: firebase.auth().currentUser.displayName,
				email: firebase.auth().currentUser.email,
			};
			dispatch("patch", user);
		},
		updateTableHeaders({ dispatch }, { id, cols, colType }) {
			dispatch("patch", {
				settings: {
					display_appointment_columns: {
						[id]: {
							[colType]: cols,
						},
					},
				},
			});
		},
	},
};
