import store from "../store";
import Vue from "vue";
export default {
    in10WorkingDays(d){
        let days = [];
        let workdays = [1,2,3,4,5];
        Date.prototype.nextDay = function() {
            var date = new Date(this.valueOf());
            date.setDate(date.getDate() + 1);
            return date;
        }
        d = d.nextDay();
        while( days.length < 10 ) {
            if( workdays.includes( d.getDay() ) ){
                days.push( d );
            }
            d = d.nextDay();
        }
        return d;
    },
    parseDateToMoment(date, tz ){
        if( !date) {
            return;
        }
        if( date.isMoment ){
            return date;
        }
        date = date.toDate ? date.toDate() : date; 
        if( !tz ){
            tz = store.state.auth.data.timezone;
        }
        if( !tz ){
            tz = "Europe/London";
        }
        return Vue.moment(date).tz(tz);
    },
    formatAsDateString( date, tz){
        let moment = this.parseDateToMoment(date, tz);
        return moment.format("DD/MM/YYYY");
    }, 
    formatAsUSDateString( date, tz){
        let moment = this.parseDateToMoment(date, tz);
        return moment.format("YYYY-MM-DD");
    }, 
    formatAsDateTimeString(date, tz){
        let moment = this.parseDateToMoment(date, tz);
        return moment.format("DD/MM/YYYY HH:mm" );
    },
    formatAsTimeString(date, tz){
        let moment = this.parseDateToMoment(date, tz);
        return moment.format("HH:mm" );
    },
    getHoursFromSlots(slots, tz ){
        let hours = []
        slots.forEach((slot) => {
            // get the earliest and latest hour marker
            let start = this.parseDateToMoment(slot.start, tz );
            let end = this.parseDateToMoment(slot.end, tz );
            let earliest = start.startOf("hour");
            let latest = end.endOf("hour");
            while (earliest < latest) {
                // deduplicating -> as we are rounding the slots up and down to get full hour markers, it's possible to get duplicates
                if (hours.length == 0 || hours[hours.length - 1] < earliest) {
                    hours.push(earliest.clone());
                }

                earliest.add(1, "hour");
            }
        });
        return hours;
    },
    getQuartersFromSlots(slots, tz ){
        let hours = []
        slots.forEach((slot) => {
            // get the earliest and latest hour marker
            let start = this.parseDateToMoment(slot.start, tz );
            let end = this.parseDateToMoment(slot.end, tz );
            let earliest = start.startOf("hour");
            let latest = end.endOf("hour");
            while (earliest < latest) {
                // deduplicating -> as we are rounding the slots up and down to get full hour markers, it's possible to get duplicates
                if (hours.length == 0 || hours[hours.length - 1] < earliest) {
                    hours.push(earliest.clone());
                }

                earliest.add(15, "minutes");
            }
        });
        return hours;
    }
}