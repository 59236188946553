<template>
    <v-list>
        <v-list-item>
            <v-list-item-title>Helpful Tips</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item v-for="(item, index) in items" :key="index" :class="{'lightest--background': index % 2 !== 0 }">
            <v-list-item-content>
                <v-list-item-title class="primary--text">{{ item.title }}</v-list-item-title>
                <v-list-item-subtitle class="wrap-text"><span v-html="item.html"></span></v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
    </v-list>
</template>
<style>
    .wrap-text {
        white-space: normal
    }
</style>

<script>

export default {
	name: "PromoteContentList",
	data: () => {
		return {};
	},
	computed: {
		items() {
			return this.$store.getters["content/top5"];
		},
	},
};
</script>
