export default {
	state: {
		contact_detail_headers: [
			{
				value: "phone",
				text: "Phone",
				source: "appointment_participant",
				display: "default",
				filterable: true,
				col_type: "appointments",
			},
			{
				value: "email",
				text: "Email",
				source: "appointment_participant",
				display: "default",
				col_type: "appointments",
				filterable: true,
			},
		],
		scheduling_table_headers: [
			{
				value: "start",
				text: "Start",
				disabled: true,
				source: "appointment",
				display: "date",
				col_type: "appointments",
				filterable: false,
			},
		],
		appointment_table_headers: [
			{
				value: "name",
				text: "Name",
				disabled: false,
				source: "appointment_participant",
				display: "prettyPrint",
				col_type: "any",
				filterable: true,
			},
			{
				value: "methodology",
				text: "Methodology",
				source: "appointment",
				col_type: "appointments",
				display: "prettyPrint",
				filterable: true,
			},
			{
				value: "appointment_status",
				text: "Status",
				col_type: "appointments",
				source: "appointment_participant",
				display: "chip",
				filterable: true,
			},
			{
				value: "quota",
				text: "Quota",
				col_type: "appointments",
				source: "appointment_participant",
				display: "prettyPrint",
				filterable: true,
			},
			{
				value: "attended",
				text: "Attended",
				col_type: "appointments",
				source: "appointment_participant",
				display: "attendance",
				filterable: false,
			},

			// {
			// 	value: "consented",
			// 	text: "Consented",
			// 	disabled: true,
			// 	col_type: "participants",
			// 	source: "appointment_participant",
			// 	display: "consent",
			// 	filterable: true,
			// },
			{
				value: "participation_link",
				text: "Link",
				col_type: "appointments",
				source: "appointment",
				display: "click_link",
				filterable: true,
			},
			{
				value: "tech_test",
				col_type: "participants",
				text: "Tech Test",
				source: "appointment_participant",
				display: "tech_test",
				filterable: false,
			},
		],
	},
	getters: {
		projectCustomColumns(_, __, rootState) {
			return (id) => {
				let project = rootState.projects.data[id];
				return project.custom_fields.map((f) => ({
					text: f,
					value: f,
					display: "default",
					filterable: true,
					col_type: "profiles",
				}));
			};
		},
		projectColumns(state, getters, rootState) {
			return (id) => {
				let project = rootState.projects.data[id];
				let cols = [];
				if( !project ){
					return cols;
				}
				if (project.include_scheduling) {
					cols = cols.concat(state.scheduling_table_headers);
				}
				cols = cols.concat(state.appointment_table_headers);
				if (!project.hide_contact_details) {
					cols = cols.concat(state.contact_detail_headers);
				}
				let custom_columns = getters.projectCustomColumns(id);
				cols = cols.concat(custom_columns);
				return cols;
			};
		},
		projectColumnsByType( _, getters ){
			return (id, type ) => {
				let cols = getters.projectColumns(id);
				return cols.filter((c) => (c.col_type == type || c.col_type == 'any' ))
				.map((c) => c.value);
			}
		}
	},
	namespaced: true,
};
