<template>
	<span> </span>
</template>

<style lang="less"></style>

<script>
	export default {
		name: "Chat",
		computed: {
			auth() {
				return this.$store.state.auth.data;
			},
		},
		watch: {
			auth: {
				immediate: true,
				handler() {
					if (this.auth.id) {
						let doc = {
							name: this.auth.name,
							email: this.auth.email
							// identifier_hash: "GzdcU3VaDeX3oPj3BdZbAWp1"
						};
						window.$chatwoot.setUser(this.auth.id, doc);
					} else {
						if (window.$chatwoot) {
							window.$chatwoot.reset();
						}
					}
				}
			}
		},
		mounted() {
			(function(d, t) {
				var BASE_URL = "https://support.m.works";
				var g = d.createElement(t),
					s = d.getElementsByTagName(t)[0];
				g.src = BASE_URL + "/packs/js/sdk.js";
				g.defer = true;
				g.async = true;
				s.parentNode.insertBefore(g, s);
				g.onload = function() {
					window.chatwootSDK.run({
						websiteToken: "xCi5QLPRW1AuDKecRvYPLbU5",
						baseUrl: BASE_URL
					});
				};
			})(document, "script");
		}
	};
</script>
