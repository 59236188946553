export default {
	firestorePath: "messages",
	firestoreRefType: "collection",
	moduleName: "messages",
	statePropName: "data",
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		send({  dispatch }, {to, body, appointment, appointmentParticipant} ) {
			var message = {
				to,
				body,
                appointment, 
                appointmentParticipant,
			};
			return dispatch("insert", message);
		},
	},
};
