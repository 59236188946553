<template>
	<v-card class="mb-2" flat outlined tile width="100%">
		<v-card-title>
			<span class="large-text font-weight-light mr-4">
				{{ project.client_name }}
			</span>
			<v-spacer></v-spacer>
			<project-dates v-if="1 == 2" :value="value"></project-dates>
		</v-card-title>
		<v-card-text>
			<v-row>
				<v-col cols="4">
						<dashboard-avatar
							:value="scheduled"
							color="lightest"
							dark
							text="Recruited"
							:i="1"
						></dashboard-avatar>
						<dashboard-avatar
							:value="confirmed"
							color="lightest"
							dark
							text="Confirmed"
							:i="2"
						></dashboard-avatar>
						<dashboard-avatar
							:value="complete"
							dark
							color="lightest"
							text="Complete"
							:i="3"
						></dashboard-avatar>
				</v-col>
				<v-col cols="6">
					<div class="bottom-contents-wrapper pb-3">
						<dashboard-chip
							class="bottom-content"
							v-for="(quota, i) in quotas"
							:key="i"
							:i="i"
							:value="quota.join(':  ')"
						></dashboard-chip>
					</div>
				</v-col>
				<v-col>
					<div class="bottom-contents-wrapper pb-3">
						<v-btn
							class="mr-2 mb-2 bottom-content"
							small
							router-link
							color="primary"
							:to="`/projects/${value}/participants`"
						>
							View Project
						</v-btn>
					</div>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
import DashboardChip from "@c/dashboard/DashboardChip.vue";
import DashboardAvatar from "@c/dashboard/DashboardAvatar.vue";
import ProjectDates from "@c/dashboard/ProjectDates.vue";
export default {
	name: "ProjectTile",
	props: {
		value: { type: String },
	},
	computed: {
		project() {
			return this.$store.state.projects.data[this.value];
		},
		appointments() {
			return this.$store.getters.getByKey(
				"appointments",
				"project",
				this.value
			);
		},
		appointmentParticipants() {
			let appointments = this.appointments.map((a) => a.id);
			return this.$store.getters.getByKeyIn(
				"appointmentParticipants",
				"appointment",
				appointments
			);
		},
		scheduled() {
			return this.appointmentParticipants.filter(
				(a) => a.appointment_status == "recruited" || a.appointment_status == "new"
			).length;
		},
		confirmed() {
			return this.appointmentParticipants.filter(
				(a) => a.appointment_status == "confirmed"
			).length;
		},
		complete() {
			return this.appointmentParticipants.filter(
				(a) => "complete" == a.appointment_status
			).length;
		},
		quotas() {
			const self = this;
			if (!this.project.quotas) {
				return [];
			}
			return this.project.quotas.map((q) => [
				q,
				self.appointmentParticipants.filter((a) => a.quota == q).length,
			]);
		},
	},
	components: { DashboardChip, DashboardAvatar, ProjectDates },
	created() {
		this.$store.dispatch("appointments/fetchByKey", {
			key: "project",
			value: this.value,
		});
	},
};
</script>
