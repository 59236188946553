<template>
	<v-list-group prepend-icon="mdi-information" v-model="active">
		<template v-slot:activator>
			<v-list-item-title>
                Information
			</v-list-item-title>
		</template>
            <nav-item :expanded="expanded" link="/info/about" text="About" icon="mdi-book-open-variant"></nav-item>
            <nav-item :expanded="expanded" link="/info/recruitment" text="Participant Recruitment" icon="mdi-comment-account"></nav-item>
            <nav-item :expanded="expanded" link="/info/frequently-asked-questions" text="FAQs" icon="mdi-frequently-asked-questions"></nav-item>
	</v-list-group>
</template>
<style lang="less">
.v-list-item {
	// padding: 0px 12px !important;
}
</style>
<script>
import NavItem from "@c/navigation/Item";
export default {
	name: "AboutNavigation",
	props: {
		expanded: { type: Boolean, default: true }, // is this currently expanded
	},

	data() {
		return {
			drawer: true,
			active: false,
			mini: false,
		};
	},
	computed: {
		helpPage() {
			return this.$route.path.split("/")[1] == "about";
		},
	},
	watch: {
		helpPage: {
			immediate: true,
			handler(value) {
				this.active = value;
			},
		},
	},
	components: {
		NavItem,
	},
};
</script>
