<template>
	<v-navigation-drawer
		v-model="drawer"
		app
		:mini-variant="mini"
		permanent
		touchless
		style="z-index: 100"
	>
		<logged-in v-if="loggedIn"></logged-in>
		<login v-else></login>
		<v-divider></v-divider>

		<v-list dense>
			<nav-item
				:expanded="!mini"
				v-for="item in items"
				:key="item.title"
				:link="item.link"
				v-bind="item"
			>
			</nav-item>
			<template v-if="loggedIn">
				<nav-item
					:expanded="!mini"
					v-for="item in authItems"
					:key="item.title"
					:link="item.link"
					v-bind="item"
				>
				</nav-item>
			</template>
		</v-list>
		<template v-slot:append>
			<v-list dense>
				<nav-item
					:expanded="!mini"
					link="/contact"
					icon="mdi-at"
					text="Contact Us"
				></nav-item>
				<about></about>
				<chat :expanded="!mini"></chat>
				<logout :expanded="!mini" v-if="loggedIn"></logout>
				<nav-item
					@click="mini = !mini"
					:expanded="!mini"
					:text="$t('buttons.collapse')"
					:icon="mini ? 'mdi-chevron-right' : 'mdi-chevron-left'"
				></nav-item>
			</v-list>
		</template>
	</v-navigation-drawer>
</template>
<script>
import Chat from "@c/ui/Chat.vue";
import LoggedIn from "@c/auth/LoggedIn";
import Login from "@c/auth/Login";
import Logout from "@c/auth/Logout.vue";
import NavItem from "@c/navigation/Item";
import About from "@c/navigation/About";

export default {
	name: "Sidebar",
	computed: {
		loggedIn() {
			return this.$store.state.auth.logged_in;
		},
	},
	data() {
		return {
			drawer: true,
			items: [{ text: "Home", icon: "mdi-home", link: "/" }],
			authItems: [
				{ text: "Projects", icon: "mdi-folder", link: "/projects" },
			],
			mini: false,
		};
	},
	components: {
		Login,
		Chat,
		LoggedIn,
		Logout,
		NavItem,
		About,
	},
	inject: ["isMobile"],
	created() {
		if (this.isMobile) {
			this.mini = true;
		}
	},
};
</script>
