<template>
	<v-sheet color="transparent" class="pa-2">
		<template v-if="haveProjects">
			<h3 class="font-weight-light mb-5">
				My Current Projects
			</h3>

				<project-tile
					v-for="project in projects"
					:key="project.id"
					:value="project.id"
				>
				</project-tile>
		</template>
		<template v-else>
			<p class="font-weight-light">
				No current projects found. If you think this is a mistake, please
				contact meisterworks.
			</p>
		</template>
	</v-sheet>
</template>

<script>
import ProjectTile from "@c/dashboard/ProjectTile"
export default {
	name: "CurrentProjects",
	computed: {
		auth() {
			return this.$store.state.auth.data.id;
		},
		projects() {
			return this.$store.state.projects.data;
		},
		haveProjects() {
			return Object.keys(this.projects).length > 0;
		},
	},
	watch: {
		auth: {
			immediate: true,
			handler(value) {
				if (value) {
					this.$store.dispatch("projects/fetchCurrentProjects");
				}
			},
		},
	},
	components: {
		ProjectTile
	}
};
</script>
