import Vue from "vue";
import utilities from "../../assets/utilities";
// import firebase from "firebase";
// import Vue from "vue";

export default {
	firestorePath: "appointments",
	firestoreRefType: "collection",
	moduleName: "appointments",
	statePropName: "data",
	namespaced: true,
	state: {
		last: null,
	},
	sync: {},
	serverChange: {
		addedHook: function(updateStore, doc, _, { dispatch }) {
			dispatch("appointmentParticipants/fetchAndAdd", {
				clauses: {
					where: [
						["show_to_client", "==", true ],
						["appointment", "==", doc.id],
						["status", "==", "published"],
					],
				},
			}, {root: true })
			dispatch("projects/fetchById", doc.project);
			return updateStore(doc);
		},
	},
	getters: {
		futureAppointmentsSorted(state) {
			let future = Object.values(state.data);
			let now = new Date();
			now.setHours(0, 0, 0, 0);
			future = future.filter((a) => a.start && a.start.toDate() > now);
			return utilities.sortByDate(future, "start");
		},
	},
	mutations: {
		CHANGE_MEDIUM(state, {id, medium}){
			Vue.set(state.data[id], "video_medium", medium )
		}
	},
	actions: {
		myNext10({ rootState, dispatch }) {
			let projects = Object.keys(rootState.projects.data);
			projects.splice(0, 9); 
			if( !projects.length ){
				return;
			}
			let limit = 10;
			let startAfter = new Date();
			startAfter.setHours(0, 0, 0, 0);

			return dispatch("fetchAndAdd", {
				clauses: {
					where: [
						["start", ">", startAfter],
						["project", "in", projects],
						["status", "==", "published"],
					],
					orderBy: "start",
					limit,
				},
			});
		},
	},
	model: {},
};
