export default {
	firestorePath: "emails",
	firestoreRefType: "collection",
	moduleName: "emails",
	statePropName: "data",
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		sendTemplate({ rootGetters, dispatch }, {id, data, template} ) {
			var mail = {
				toUids: [id],
				template: {
					data,
					name: template,
				},
				assessment: rootGetters["assessment/id"],
			};
			return dispatch("insert", mail);
		},
	},
};
