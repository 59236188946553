
export default {
	firestorePath: "consents",
	firestoreRefType: "collection",
	moduleName: "consents",
	statePropName: "data",
	namespaced: true,
	state: {},
	serverChange: {},
	getters: {
	},
	mutations: {},
	actions: {
	},
	model: {},
};
